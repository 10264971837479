.sidebar{
    background-color: #2F3436;
    background-color: #201f20;

    width: 250px;
    height: 100vh;
    position: sticky;
    top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 3px 3px 20px rgb(32, 31, 32, 0.3);
    transition: transform 0.3s ease-in-out;

}



.logo-admin{
    width: 50px;
    height: 50px;
    border-radius: 15px;
    background-color: #e0ddff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #111111;

}

.header-admin{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 30px 0;
    /* background-color: #111111; */
}
.header-admin p{
    margin-left: 10px;
    color: #fff;
    font-size: 16px;
}

.navigation-admin{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 1; /* Основное содержимое занимает доступное пространство */
    overflow-y: auto; 
    /* padding: 0 20px; */
}   
.nav-link{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 15px 20px;
    /* margin-bottom: 10px; */
    /* border-radius: 10px; */
    transition-duration: 0.3s;
    cursor: pointer;
    /* background-color: rgba(189, 189, 189, 0.02); */
    /* box-shadow: 0 3px rgba(0, 0, 0, 0.1); */

    
}
.nav-link:hover{
    background-color: #111111;
    
    background-color: rgba(189, 189, 189, 0.2);
    /* background-color: rgba(255, 255, 255, 0.705); */
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);

    

}

.nav-icon{
    width: 20px;

}
.nav-title{
    margin-left: 40px;
    color: #D8D8D8;
}

.nav-active{
    background-color: rgba(135, 144, 150, 0.384);
    background-color: rgba(43, 134, 165, 0.384);
    background-color: #2F3436;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);

}


/* footer */

.footer-admin{
    background-color: #111111;
    width: 100%;
    padding: 10px 20px;
}

.footer-admin p{
    color: #e9e9e9;
}



.sidebar-closed {
    /* transform: translateX(-100%); */
}

.sidebar-open {
    /* transform: translateX(0); */
}


/* Стили для бургер-меню */
.burger-menu {
    position: fixed;
    top: 20px;
    left: 20px;
    background-color: #201f20;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    z-index: 1000;
}

.burger-menu img {
    width: 30px;
    height: 30px;
}


/* Прячем Sidebar и показываем бургер-меню на мобильных устройствах */
@media (max-width: 768px) {
    .burger-menu {
        display: block;
    }
}

